ExecuteOrDelayUntilBodyLoaded(() => {
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        dataLayer.push(arguments);
    }
    gtag('js', new Date());
    var configId = 'UA-109217335-4';
    if (window.location.host == "myaps.alberta.ca") configId = 'UA-109217335-1';
    if (window.location.host == "myapsuat.sp.alberta.ca") configId = 'UA-109217335-3';
    if (window.location.host == "myapsdev.sp.alberta.ca") configId = 'UA-109217335-2';
    gtag('config', configId);

    var $script = jQuery('<' + 'script' + '/>')
        .attr('id', "gtag")
        .attr('type', "text/javascript")
        .attr('async', "async")
        .attr('src', "https://www.googletagmanager.com/gtag/js?id=" + configId);

    jQuery('body').append($script);

    const iconsFolderUrl = `${_spPageContextInfo.siteAbsoluteUrl}/Style Library/psc/favicons`;
    const $favicon = jQuery('#favicon');
    $favicon.attr('href', `${iconsFolderUrl}/favicon.ico?rev=1`)

    jQuery(`
    <link rel="shortcut icon" type="image/ico" href="${iconsFolderUrl}/favicon.ico" /> <!-- 128x128 -->
    <link rel="icon" type="image/png" href="${iconsFolderUrl}/favicon.png" /> <!-- 128x128 -->
    <link rel="icon" type="image/png" sizes="192x192" href="${iconsFolderUrl}/favicon-192.png" /> <!-- 192x192 recommended for Android -->
    <link rel="apple-touch-icon" href="${iconsFolderUrl}/favicon-57.png"> <!-- 57x57 (precomposed) for iPhone 3GS, pre-2011 iPod Touch and older Android devices -->
    <link rel="apple-touch-icon" sizes="72x72" href="${iconsFolderUrl}/favicon-72.png"> <!-- 72x72 (precomposed) for 1st generation iPad, iPad 2 and iPad mini -->
    <link rel="apple-touch-icon" sizes="114x114" href="${iconsFolderUrl}/favicon-114.png"> <!-- 114x114 (precomposed) for iPhone 4, 4S, 5 and post-2011 iPod Touch -->
    <link rel="apple-touch-icon" sizes="144x144" href="${iconsFolderUrl}/favicon-144.png"> <!-- 144x144 (precomposed) for iPad 3rd and 4th generation -->
    `).insertAfter($favicon);


});